.home__section {
  display: flex;
  flex-direction: column;
  // width: 80%;
  // height: 60%;
  padding: 13% 0 0 16%;
  opacity: 0;
  transform: translateY(200px);
  transition: transform 0.7s ease-out, opacity 1s ease-out;

  .home-main-text {
    font-family: "Fira Mono", monospace !important;
    font-size: 80px;
    letter-spacing: -7px;
  }

  .home-submain-text-wrapper {
    position: relative;
    right: -4%;
  }
  
  .glitch {
    font-size: 60px;
  }
  .glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: -2px 0 magenta;
    top: 0;
  }
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -2px 0 lightgreen;
    top: 0;
  }
  // .home__wrapper {
   
  // }

}

.home__section.loaded {
  transform: translateY(0);
  opacity: 1;
}

.home__section.visible {
  transform: translateY(0);
  opacity: 1; 
}