.nav {
  height: 80px;
  position: fixed;
  width: 100%;
  top: -80px; 
  z-index: 9999;
  transition: top 0.6s ease; 
  &-initial {
    top: -80px; 
  }
  &-showed {
    top: 0; 
  }
  &-hidden {
    top: -80px;
  }
  &-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 78%;
    margin: 0 auto;
    padding: 10px;
    &_left  {
      display: flex;
    }
    &_right {
      display: flex;
      align-items: center;
    }
  }
  &-logo {
    height: 100%;
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-right: 10px;
  }
  &-logo:hover &-logo-img {
    scale: 1.05;
    cursor: pointer;
  }
  &-logo:active &-logo-img {
    scale: 0.9;
    cursor: pointer;
  }
  &-logo-img {
    height: 50px;
    width: 50px;
    transition: all 0.5s;
  }
  &-links {
    display: flex;
    align-items: center;
    

    &-link {
      margin: 0 10px;
      font-family: 'Bebas Neue', cursive;
      letter-spacing: 2px;
      
      --border: 2px;    /* the border width */
      --slant: 0.5em;   /* control the slanted corners */
      --color: white; 
      
      font-size: 20px;
      padding: 0.4em 1.2em;
      border: none;
      cursor: pointer;
      font-weight: bold;
      color: var(--color);
      background: 
        linear-gradient(to bottom left,var(--color)  50%,#0000 50.1%) top right,
        linear-gradient(to top   right,var(--color)  50%,#0000 50.1%) bottom left;
      background-size: calc(var(--slant) + 1.3*var(--border)) calc(var(--slant) + 1.3*var(--border));
      background-repeat: no-repeat;
      box-shadow:
        0 0 0 200px inset var(--s,#0000),
        0 0 0 var(--border) inset var(--color);
      clip-path: 
        polygon(0 0, calc(100% - var(--slant)) 0, 100% var(--slant),
                100% 100%, var(--slant) 100%,0 calc(100% - var(--slant))
                );
      transition: color var(--t,0.3s), background-size 0.3s;
    }
    &-link:focus-visible {
      outline-offset: calc(-1*var(--border));
      outline: var(--border) solid #000c;
      clip-path: none;
      background-size: 0 0;
    }
    &-link:hover,
    &-link:active{
      background-size: 100% 100%;
      color: black;
      --t: 0.2s 0.1s;
    }
    &-link.active {
      background-size: 100% 100%;
      color: black;
      --t: 0.2s 0.1s;
      text-decoration: underline;

    }
    &-link:active {
      --s: #21D7DB;
      transition: none;
    }
  }
  
  &-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 7px;
    a {
      border-radius: 4px 7px 7px 4px;
      z-index: 2;  
      text-shadow: none;
      font-family: 'Bebas Neue', cursive;
      letter-spacing: 2px;
      color: #fff;
      padding: 1px 6px 1px 8px;
      position: relative; 
      overflow: hidden;
      display: block;
      transition: color .3s ease-in-out;
      &::after {
        z-index: -1; 
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #00E6F6;
        transform: translateX(101%); 
        transition: transform .3s ease-in-out;
        border-radius: 6px 7px 7px 6px;
      }
      &:hover::after {
        transform: translateX(0%);
        background-color: #fff;
        box-shadow: 
          0 0 5px #00E6F6,
          0 0 25px #00E6F6,
          0 0 50px #00E6F6,
          0 0 200px #00E6F6;
      }
      &:active::after {
        background: #00E6F6;
      }
    }
  
    a:hover {
      border-radius: 7px 7px 7px 7px;
      color: #000;
      background-color: rgba($color: #000000, $alpha: 0.5);
      box-shadow: 
        0 0 7px #00E6F6,
        0 0 10px #00E6F6,
        0 0 10px #00E6F6,
        0 0 21px #00E6F6,
        0 0 27px #00E6F6,
        inset 0 0 10px #00E6F6,
        inset 0 0 21px #00E6F6,
        inset 0 0 27px #00E6F6; 
      text-shadow:
        0 0 4px #fff,
        0 0 5px #fff,
        0 0 7px #fff,
        0 0 9px #fff,
        0 0 5px var(--primary-color-blue),
        0 0 80px var(--primary-color-blue),
        0 0 90px var(--primary-color-blue),
        0 0 100px var(--primary-color-blue),
        0 0 150px var(--primary-color-blue),
        0 0 200px var(--primary-color-blue);
    }
  }

  &-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 15px;

    &-button {
      height: 45px;
      width: 45px;
      display: flex; 
      justify-content: center;
      align-items: center;
      margin: 0px 4px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      font-weight: bold;
      border: 1px solid white;
      border-radius: 100%;
      letter-spacing: 4px;
      overflow: hidden;
      transition: 0.5s;
      cursor: pointer;
    }
    &-button img {
      height: 50%; 
      width: 50%; 
      transition: transform 0.5s, filter 0.5s; 
    }
    &-button_cv img {
      height: 60%; 
      width: 60%; 
    }
    &-button:hover img {
      filter: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(90%);
      transform: scale(1.2); 
    }
    &-button:hover {
      background: #03e9f4;
      box-shadow: 
        0 0 7px #03e9f4,
        0 0 10px #03e9f4,
        0 0 21px #03e9f4,
        0 0 27px #03e9f4;  
      border: 1px solid #03e9f4;
    }
    &-button:active {
      background: #fff;
      box-shadow: 
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 27px #fff;  
      border: 1px solid #fff;
    }
    &-button_git:hover {
      background: #00FF00;
      box-shadow: 
        0 0 7px #00FF00,
        0 0 10px #00FF00,
        0 0 21px #00FF00,
        0 0 27px #00FF00;             
      border: 1px solid #00FF00;
    }
    &-button_git:active {
      background: #fff;
      box-shadow: 
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 27px #fff;                       
      border: 1px solid #fff;
    }
    &-button_cv:hover {
      background: #FF0058;
      box-shadow: 
        0 0 7px #FF0058,
        0 0 10px #FF0058,
        0 0 21px #FF0058,
        0 0 27px #FF0058;                   
      border: 1px solid #FF0058;
    }
    &-button_cv:active {
      background: #fff;
      box-shadow: 
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 27px #fff;  
      border: 1px solid #fff;
    }
  }
}

.nav-connect-btn, .nav-connect-btn::after {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 50px;
  font-size: 36px;
  font-size: 30px;
  font-family: 'Bebas Neue', cursive;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  line-height: 88px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
}
.nav-connect-btn::after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'CONNECT NOW';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}
.nav-connect-btn:hover {
  scale: 1.015;
}
.nav-connect-btn:active {
  scale: 1;
}
.nav-connect-btn:hover::after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}




// -webkit-transform: skewX(25deg);   For cutted buttons edges. For li 
// -webkit-transform: skewX(-25deg);  For li -> inside item. 
