.about {
  display: block;
  position: absolute;
  width: 40%;
  height: 80%;
  left: 9%;
  top: calc(50% + 20px);
  transform: translateY(-50%);
  padding: 1%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  border-radius: 30px;
}
.about-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about__link {
  color: var(--primary-color-blue);
  transition: all 0.3s;
  &:hover {
    color: var(--primary-color-pink);
    transition: all 0.3s;
  }
}
.section-about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  height: 100%;  
  .bubble_wrap {
    min-height: 50% ;
  }
}
.about-visible {
  opacity: 1;
  transition: opacity 2s ease;
}
.about-not-visible {
  opacity: 0;
  transition: opacity 0.5s ease;
}
