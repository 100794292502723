aside {
  opacity: 0;
  transition: all 0.7s ease-in-out;
  .sidepanel {
    width: 370px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 45%;  
    left: -5%;
    z-index: 9999;
    transform: rotate(-90deg);
    &__text {
      cursor: default;
      display: flex;
      align-items: center;
      padding-bottom: 2px;
    }
    &__line {
      width: 80px;
      height: 0px;
      border: 2px solid white;
      margin: auto 0;
    }
  }
  a {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transform: rotate(90deg);
    transition: all 0.5s , 
  }  
  a:hover {
    scale: 1.3;
    filter: 
      drop-shadow(0 0 3px var(--primary-color-pink)) 
      drop-shadow(0 0 5px var(--primary-color-pink)) 
      drop-shadow(0 0 7px var(--primary-color-pink)) ;
  }
  a:active {
    scale: 1;
  }
  a img {
    width: 100%;
    height: 100%;
  }
}
aside.show {
  opacity: 1;
}

