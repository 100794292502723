
@media only screen and (min-width: 1921px) {

  .navbar__wrapper {
    height: 98% !important;
    width: 97% !important;
  }

  aside {
    .sidepanel {
      top: 43% !important;  
    }
  }

  .home__section {
    padding: 15% 0 0 16%!important;
    // margin-left: 17% !important;
  }

  .projects {
    &__header {
      top: 7% !important;
    }
  }
  
  .connect {
    &__wrapper {
      padding: 15px !important;
      justify-content: space-around !important;
      transform: translate(35%, -50%) !important;
    }
    &__imgarea {
      width: auto !important;
    }
    form {
      margin-top: 30px !important;
    }
  }

  .projects__wrapper {
    margin-top: 10px !important;
  }

  .section__header_text {
    font-size: 28px !important;
  }

  .bubble_wrap {
    padding: 20px !important;
  }
  .connect__wrapper {
    box-shadow: none !important;
  }
  .projects__card_pink:hover {
    box-shadow: none;
  }
  .projects__card_blue:hover {
    box-shadow: none;
  }

}
@media only screen and (max-width: 1920px) {

  .section__header_text {
    font-size: 24px !important;
  }
  .bubble_wrap p {
    font-size: 18px !important;
    overscroll-behavior: none;
  }
  .block_sub-text {
    font-size: 18px !important;
    overscroll-behavior: none;
  }
  .projects__card h3 {
    font-size: 20px !important;
  }
  .projects__wrapper {
    width: 100% !important;
  }
  .projects__card {
    padding: 15px 15px !important;
    p {
      font-size: 16px !important;
    }
    .projects__hashtags {
      display: flex !important;
      flex-wrap: wrap !important;
      width: 85% !important;
      bottom: 10px !important;
    }
  }
  .projects__card-img-holder {
    height: 150px !important;
  }
  .about__section .container {
    margin: -65px 0 !important;
  }
 
  .connect {
    &__wrapper {
      padding: 15px !important;
      justify-content: space-around !important;
      
    }
    &__imgarea {
      width: auto !important;
    }
    form {
      margin-top: 30px !important;
    }
  }
 
}

@media only screen and (max-width: 1700px) {

  .nav-container {
    width: 100% !important;
    padding: 10px 9% 10px 7% !important;
  }
  .nav-contacts {
    display: none !important;
  }
  aside .sidepanel {
    left: -7% !important;
  }
  // .projects__button-wrapper {
  //   margin-top: 10px !important;
  // }
  .projects__card-img-holder {
    height: 110px !important;
  }

  .about {
    border-radius: 14px !important;
    .bubble_wrap {
      border-radius: 9px;
    }
    .section__header {
      border-radius: 9px;
    }
  }

  .projects {
    padding: 80px 0 0 0 !important;
  }

  .connect__wrapper {
    top: 50% !important;
  }

  .navbar__wrapper {
    padding-top: 50px !important;
  }
  .navbar__link {
    margin-bottom: 15px !important;
  }
  .navbar__email-mob {
    margin-top: 5px !important;
  }
  .projects-modal__container {
    width: 60vw !important;
  }


  
}

@media only screen and (max-width: 1400px) {
  .nav-container {
    padding: 10px 10% 10px 7% !important;
  }
  .nav-socials {
    display: none !important;
  }
  .connect {
    padding: 0 10% !important;
    &__wrapper {
      max-width: none !important;
      width: auto;
      transform: translate(0%, -50%) !important;
    } 
    &__textarea {
      min-width: 30% !important;
    }
  }
  // .about__section {
  //   .bubble_wrap {
  //     max-height: 70vh !important;
     
  //   }
  // }
  aside .sidepanel {
    left: -10% !important;
  }
  .projects {
    .projects__button-wrapper {
      height: 8%;
      margin-bottom: 0.5%;
    }
    .tab-content {
      height: 91%;
      // display: flex;
      // align-items: center;
      // background-color: green;
    }
    &__card  {
      margin: 0 !important;
    }
    .projects__card_pink:hover {
      box-shadow: none;
    }
    .projects__card_blue:hover {
      box-shadow: none;
    }
  }
  .projects__small-arrow-wrapper .small-arrow-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .projects__zoom {
    height: 100% !important;
  }
  .projects__wrapper-for-links {
    align-items: flex-end !important;
  }
  .projects__card-img-holder {
    height: 50% !important;
  }
  .navbar__lower-icons {
    display: none !important;
  }
  .navbar__email-mob_visible {
    margin-top: 17px !important;
  }
  .projects-modal__container {
    width: 80vw !important;
  }


 
}

@media only screen and (max-width: 1200px) {

  .nav-connect-btn {
    display: none !important;
  }
  aside .sidepanel {
    display: none !important;
  }

  .hamburger {
    .hamburger__line {
      background-color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
  }

  .fz-text {
    font-size: 16px !important;
  }
  .fz-header {
    font-size: 20px !important; 
  }

  .navbar {
    width: 25% !important;
  }
 

  .about__section .container {
    right: 9% !important;
    margin: -45px 0 !important;
    transform: scale(0.6) !important;
  }
  .section__header {
    margin-bottom: 5px !important;
  }
  .about {
    padding: 10px !important;
    width: 50% !important;
    border-radius: 25px !important;
  }

  .projects {
  
    .projects__button-wrapper_block {
      width: 100% !important;
    }
    .projects__header {
      display: inline-block;
      top: 15px;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
    .section__header {
      display: none !important;
    }
    .projects__wrapper {
      margin-top: 3% !important;
      justify-content: space-around !important;
      align-items: center !important;
    }
    .bubble_wrap_projects {
      top: 14% !important;
    }
    .projects__wrapper {
      margin-top: 1% !important;
    }
    &__zoom {
      display: none !important;
    }
  }

  .connect {
    &__wrapper {
      position: absolute !important;
      margin-left: unset !important;
      left: 1% !important;
    }
  }

}

@media only screen and (max-width: 991px) {

  .nav {
    display: none !important;
  }

  .home__section {
    padding: 13% 0 0 6%!important;
  }
  .home__section .glitch, .home__section .glow {
    font-size: 50px !important;
  }

  #skills__section {
    .about {
      width: 98% !important;
      left: 1% !important;
      top: 29% !important;
      padding: 5px !important;
      border-radius: 16px !important;
      min-height: unset !important;
      transform: none !important;
      p {
        // height: 300px !important;
        overflow: auto !important;
  
      }
    }
  }

  .connect {
    &__wrapper {
      width: 98% !important;
      top: unset !important;
      bottom: 2% !important;
      transform: translate(0%, -10%) !important;
    }
    &__textarea-wrapper {
      width: 100% !important;
    }
    &__imgarea-img {
      min-width: 250px !important;
    }
    .form .button {
      width: 50% !important;
    }
   
  }

  // .projects .projects__card {
  //   width: 40% !important;
  // }
  .projects {
    padding: 15px 0 0 0 !important;
  }

  .connect {
    &__wrapper {
      transform: unset !important;
      box-shadow: unset !important;
    }
  }

  .projects-modal__container {
    width: 98vw !important;
    padding: 10px !important;
  }
  .projects-modal__wrapper-for-hashtags {
    margin-left: 10px !important;
  }
  .img-fullscreen-slider .slick-slide img {
    width: 90% !important;
  }


}

@media only screen and (max-width: 768px) {

  .fz-header {
    font-size: 18px !important; 
  }



  .navbar {
    width: 30% !important;
  }
  .hamburger {
    top: 20px !important;
    right: 30px !important;
  }
 
  
  .home__section {
    width: 100% !important;
    padding: 23% 0 0 4%!important;
  }
  .home-main-text  {
    font-size: 45px !important;
    margin-bottom: 0 !important;
  }
  .home__section .home-submain-text-wrapper {
    margin: 0px 0px  !important;
    right: -7% !important;
  }
  .home__section .glitch, .home__section .glow {
    font-size: 30px !important;
  }
  .home__section .glitch::before, .home__section .glow::before {
    left: -1px !important;
  }
  .home__section .glitch::after, .home__section .glow::after {
    left: 1px !important;
  }

  .area {
    display: none !important;
  }
  
  .connect {
    .form .button {
      width: 100% !important;
    }
    &__textarea-header {
      display: flex !important;
      align-items: flex-end !important;
      gap: 15px !important;
      margin-bottom: 10px !important;
    }
    &__textarea-wrapper {
      padding: unset !important;
    }
    &__textarea {
      padding: 0px !important;
    }
    &__imgarea {
      width: 43% !important;
      display: none !important;
      &-img {
        display: none;
        &-mobile-small {
          display: block !important;
          width: 150px !important;
        }
      }
    }

  }


  .projects .projects__card {
    width: 50% !important;
  }
  .projects {
    .bubble_wrap_projects {
      min-width: 90% !important;
    }
  }
  .projects .projects__tab-component {
    top: 18% !important;
  }
  .projects .projects__card {
    width: 60% !important;
    height: 100% !important;
    margin: 0 auto !important;
  }
  .projects .projects__button-wrapper {
    margin-bottom: 1%;
  }
  .slick-slide {
    padding: 0 !important;
  }
  .projects .slick-slider {
    width: 100% !important;
  }
  // .projects .slick-slide div {
  //   height: 100% !important;
  // }
  // .slick-slide {
  //   div {
  //     height: 100% !important;
  //     width: 100% !important;
  //   }
  // } 

  .navbar__wrapper {
    padding-top: 50px !important;
  }
  .navbar__link {
    margin-bottom: 10px !important;
  }
  .navbar__neonText  {
    height: 7% !important;
  }

  .navbar__wrapper_links {
    margin-top: 12px !important;
  }

  .block_sub-title {
    font-size: 18px !important;
  }
  .block_sub-text {
    font-size: 16px !important;
  }

  .projects .slick-prev {
    left: 4% !important;
  }
  .projects .slick-next {
    right: 4% !important;
  }

}



@media only screen and (max-width: 650px) {

  .navbar {
    width: 45% !important;
  }
  .navbar__wrapper {
    background-color: unset !important;
  }
  
  .about {
    width: 98% !important;
    height: 70% !important;
    left: 1% !important;
    top: 36% !important;
    padding: 12px !important;
    border-radius: 16px !important;
    // min-height: unset !important;
    p {
      // height: 300px !important;
      overflow: auto !important;
    }
  }

  .skills__section {
    .about {
      top: 64% !important;
    }
  }


  .bubble_wrap {
    border-radius: 12px !important;
  }


  .projects .projects__card {
    width: 80% !important;
  }

  .connect__wrapper {
    bottom: 1% !important;
  }

  .section__header {
    margin-bottom: 0 !important;
    line-height: unset !important;
    padding: 7px 13px 7px 13px !important;
  }
  .section-about-wrapper {
    gap: 0 !important;
  }

  .projects .slick-prev {
    left: 1% !important;
  }
  .projects .slick-next {
    right: 1% !important;
  }

}


@media only screen and (max-width: 550px) {

  .hamburger {
    right: 18px !important;
  }

  .navbar {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .home-main-text {
    margin-left: 20px !important;
  }

  .projects {
    padding: 70px 0 0 0 !important;
  }
  .projects .projects__header {
    top: 14% !important;
  }
  .projects .bubble_wrap_projects {
    top: 10% !important;
  }
  .projects .projects__tab-component {
    top: 14% !important;
  }
  .projects .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
  }
  .projects .projects__wrapper {
    justify-content: space-between !important;
  }
  .projects__tab-button {
    padding: 10px 0 !important;
  }
  .projects .projects__button-wrapper_block {
    width: 90% !important;
  }

  .projects .pagination {
    margin-top: 0% !important;
  }

  .connect__email-mob {
    display: none !important;
  }

  .projects .projects__card {
    width: 90% !important;
  }
  .projects .projects__button-wrapper {
    margin-bottom: 3%;
  }
  .connect__wrapper {
    padding: 10px !important;
  }
  .connect__textarea-header {
    gap: 7px !important;
  }
  .connect {
    .section__header_text {
      font-size: 22px !important;
    }
  }
  .connect__links {
    padding: 15px 20px 10px 5px !important;
  }
}