@import './loading/loading.scss';
@import '../page-components/menu/menu.scss';
@import './navbar/navbar.scss';
@import './side-panel/side-panel.scss';
@import './home-section/home-section.scss';
@import './about-section/about-section.scss';
@import './skills-section/skills-section.scss';
@import './projects-section/projects-section.scss';
@import './contact-content/contact-content.scss';

.interface__section {
  // height: 100vh;
  width: 100vw;
  position: relative;
}
// .interface__section {
//   height: 582px;
//   width: 100%;
//   position: relative;
//   display: block;
//   // overflow: hidden;
// }

// .test_button {
//   position: absolute;
//   top: 0px;
// }