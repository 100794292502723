.hamburger {
  // cursor: pointer;
  background-color: rgba($color: #000000, $alpha: 0);
  border: none;
  display: block;
  height: 40px;
  width: 40px;
  position: absolute !important;
  top: 20px;
  right: 40px;
  z-index: 10000;
  &:hover &__line {
    background-color: #fff;
    border-radius: 10px;
    scale: 1.1;
    box-shadow: 
      0 0 4px #fff,
      0 0 7px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink),
      0 0 21px var(--primary-color-pink),
      0 0 42px var(--primary-color-pink);
  }
  &__line {
    display: block;
    margin: 8px 0;
    background-color: #fff;
    height: 4px;
    width: 40px;
    transition: all 0.5s;
    border: 2px solid #fff;
    border-radius: 10px;
    box-shadow: 
      0 0 2px #fff,
      0 0 2px #fff;

    &_white {
      background-color: #fff;
      border: 2px solid #fff;
      border-radius: 10px;
      box-shadow: 
        0 0 1px #fff,
        0 0 1px #fff,
        0 0 10px var(--primary-color-purple),
        0 0 10px var(--primary-color-purple),
        0 0 25px var(--primary-color-purple),
        0 0 10px var(--primary-color-purple);
    }
  }
  &__rotate-45 {
    transform: rotate(45deg);
    position: relative;
    top: 7px;
    transition: all 0.5s;
  }
  &__rotate-minus-45 {
    transform: rotate(-45deg);
    position: relative;
    top: -5px;
    transition: all 0.5s;
  }
}

.navbar {
  width: 17%;
  height: 100%;
  display: flex;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 600;
  transition: all 0.6s;
  background: linear-gradient(96deg, rgb(5, 0, 253) 10%, rgb(214, 0, 163) 100%);
  opacity: 0.9;
  border-radius: 30px 0px 0px 30px;
  &__neonText {
    height: 6%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    box-shadow: 
      0 0 10px var(--primary-color-purple),
      0 0 10px var(--primary-color-purple),
      0 0 25px var(--primary-color-purple),
      0 0 10px var(--primary-color-purple);
    margin: 15px 0;
    transition: all 2s;
  }
  &__hidden {
    right: -100% !important;
    transition: all 1s;
  }
  &__link {
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 3px;
    font-size: 23px;
    cursor: pointer;
    background-color: rgba($color: #000000, $alpha: 0);
    opacity: 0;
    margin-bottom: 30px;
    transition: all 0.3s;
    transition: opacity 1s ease-in-out, text-shadow 0.2s ease, scale 0.3s, transform 0.3s;
  }
  &__link--visible {
    opacity: 1;
  }
  &__link:hover {
    transition: all 0.2s;
    scale: 1.1;
    box-shadow: 
      0 0 1px #fff, 
      0 0 2px #fff, 
      0 0 10px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink),
      0 0 25px var(--primary-color-pink);
  }
  &__link:active {
    transition: all 0.2s;
    scale: 1;
  }
  &__link.active {
    text-shadow:
      0 0 1px #fff,
      0 0 3px #fff,
      0 0 5px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink),
      0 0 21px var(--primary-color-pink);
      // border: 3px solid #fff;
  }
  &__wrapper {
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;
    z-index: 200;
    height: 97%;
    width: 95%;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    right: 0px;
    &_links {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 60%;
      display: flex;
      justify-content: space-around; 
    }
    &_icons {
      opacity: 0;
      height: 30px;
      filter: drop-shadow(0 0 5px var(--primary-color-purple)) drop-shadow(0 0 7px var(--primary-color-purple));
      transition: opacity 1s ease-in-out, transform 0.3s; 
      will-change: transform, opacity;
    }
    &_icons--visible {
      opacity: 1;
      
    } 
    &_icons:hover {
      transform: scale(1.3) translateZ(0);
      filter: 
      drop-shadow(0 0 3px var(--primary-color-pink)) 
      drop-shadow(0 0 5px var(--primary-color-pink)) 
      drop-shadow(0 0 7px var(--primary-color-pink)) ;
    }
    &_icons:active {
      transform: scale(1.1) translateZ(0);
    }
  }
  &__divider {
    opacity: 0;
    width: 70%;
    height: 4px;
    border: 2px solid #fff;
    box-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 10px var(--primary-color-purple), 0 0 10px var(--primary-color-purple), 0 0 25px var(--primary-color-purple), 0 0 10px var(--primary-color-purple);
    transition: all 1s ease-in-out;
    border-radius: 10px;
  }
  &__divider_visible {
    opacity: 1;
  }
  &__lower-icons {
    width: 50%;
    margin: 10px 0px;
    display: flex;
    justify-content: space-around;
    button {
      background-color: rgba($color: #000000, $alpha: 0);
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      img {
        height: 35px;
      }
      .navbar__wrapper_icons_cover-latter {
        height: 32px;
      }
    }
   
  }
  &__email-mob {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    &_visible {
      opacity: 1;
    }
    a {
      display: flex;
      border-radius: 7px 7px 7px 7px;
      z-index: 2;  
      text-shadow: none;
      letter-spacing: 1px;
      color: #fff;
      padding: 1px 8px 1px 8px;
      position: relative; 
      overflow: hidden;
      display: block;
      transition: all .3s ease-in-out;
      font-size: 18px;
      font-size: 20px;
      overflow: visible;
      transition: 0.3s all;
      text-align: center;
      text-shadow:
        0 0 1px #fff,
        0 0 2px #fff,
        0 0 1px var(--primary-color-purple),
        0 0 3px  var(--primary-color-purple),
        0 0 5px  var(--primary-color-purple),
        0 0 5px  var(--primary-color-purple),
        0 0 5px  var(--primary-color-purple),
        0 0 10px  var(--primary-color-purple),
        0 0 10px  var(--primary-color-purple),
        0 0 10px  var(--primary-color-purple),
        0 0 15px  var(--primary-color-purple),
        0 0 15px  var(--primary-color-purple),
        0 0 15px  var(--primary-color-purple); 
      &:hover {
        border-radius: 7px 7px 7px 7px;
        text-shadow:
          0 0 1px #fff,
          0 0 2px #fff,
          0 0 1px var(--primary-color-pink),
          0 0 3px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink); 
        scale: 1.15;
      }
      &:active {
        scale: 1;
      }
    }
  }
}

