.connect {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 500000;
  top: 0;
  &__title {
    font-size: 45px;
    font-weight: 700;
    cursor: default;
  }
  &__wrapper {
    position: relative;
    display: flex;
    background-color: #1E1E1E;
    border-radius: 10px;
    padding: 20px;
    border-radius: 20px;
    gap: 20px;
    top: 50%;
    max-width: 1000px;
    transform: translate(20%, -50%);
    &_modal {
      position: relative;
      display: flex;
      background-color: #1E1E1E;
      border-radius: 10px;
      padding: 20px;
      border-radius: 20px;
      gap: 20px;
      top: 50%;
      max-width: 1000px;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 10px var(--primary-color-pink), 0 0 10px var(--primary-color-pink), 0 0 25px var(--primary-color-pink), 0 0 10px var(--primary-color-pink);
      opacity: 1 !important;
    }
  }
  &__imgarea {
    height: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;
    z-index: 50000;
    background-color: #1E1E1E;
    &-img {
      position: relative;
      border-radius: 15px;
      width: auto;
      max-height: 100%;
      z-index: 300;
      height: 100%;
      object-fit: cover;
      &-mobile {
        display: none;
        border-radius: 15px;
        &-small {
          display: none;
          border-radius: 15px;
          margin-top: 10px;
        }
      }
    }
  }
  &__divider {
    position: relative;
    width: 80px;
    height: 4px;
    border: none !important;
    background-color: rgba($color: #000000, $alpha: 0);
  }
  &__links {
    display: flex;
    justify-content: space-between; 
    width: 120px;
    padding: 15px 8px 10px 8px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & img {
      height: 22px;
    }
  }
  &__email-mob {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    a {
      margin: 1px 0;
      border-radius: 7px 7px 7px 7px;
      z-index: 2;  
      text-shadow: none;
      letter-spacing: 1px;
      color: #fff;
      padding: 1px 8px 1px 8px;
      position: relative; 
      overflow: hidden;
      display: block;
      transition: all .3s ease-in-out;
      font-size: 18px;
      overflow: visible;
      transition: 0.3s all;
      &:hover {
        border-radius: 7px 7px 7px 7px;
        text-shadow:
          0 0 1px #fff,
          0 0 2px #fff,
          0 0 1px var(--primary-color-pink),
          0 0 3px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 5px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 10px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink),
          0 0 15px  var(--primary-color-pink); 
        scale: 1.04;
      }
      &:active {
        scale: 1;
      }
    }
  }
  &__textarea {
    position: relative;
    background-color: #1E1E1E;
    border-radius: 10px;
    padding: 0 10px;
    z-index: 300;
    display: flex;
    justify-content: center;
  }
  &__textarea-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    z-index: 300;
    line-height: 30px;
  }
  .form {
    overflow: visible;
    max-width: 590px;
    display: flex;
    flex-direction: column;
    position: relative; 
    margin-top: 25px; 
    input {
      transition: all 0.3s;
    }
    input:hover {
      outline: none;
      border: none;
      border: 0px solid #000;
      border-radius: 7px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
    }
    input:focus {
      outline: none;
      border: none;
      border: 0px solid #000;
      border-radius: 10px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
    }
    textarea {
      padding: 15px 15px;
      transition: all 0.3s;
    }
    textarea:hover {
      outline: none;
      border: none;
      border: 0px solid #000;
      border-radius: 10px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
    }
    textarea:focus {
      outline: none;
      border: none;
      border: 0px solid #000;
      border-radius: 10px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
    }
    .focus-container {
      transition: all 0.3s;
      height: 100%;
    }
    .focus-container label{
      transition: all 0.3s;
    }
    .focus-container:focus-within label {
      transition: all 0.3s;
      color: #ffffff;
      text-shadow:
        0 0 1px #fff,
        0 0 2px #fff,
        0 0 1px var(--primary-color-pink),
        0 0 3px  var(--primary-color-pink),
        0 0 5px  var(--primary-color-pink),
        0 0 5px  var(--primary-color-pink),
        0 0 5px  var(--primary-color-pink),
        0 0 10px  var(--primary-color-pink),
        0 0 10px  var(--primary-color-pink),
        0 0 15px  var(--primary-color-pink),
        0 0 15px  var(--primary-color-pink); 
    }
    &__inputs {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      &_item {
        width: 49%;
      }
    }
    &__box {
      overflow: visible !important;
      position: relative; 
      width: 280px;
      border-radius: 7px;
    }
    &__box input {
      width: 100%;
      height: 45px;
      padding-left: 15px;
      background-color: rgba($color: #000000, $alpha: 0);
      border: none;
      border-radius: 7px;
    }
    &__box label {
      color: #ffffff;
      display: block;
      position: absolute;
      top: -31px;
      left: -7px;
      background-color: transparent;
      padding: 0 10px; 
    }
    &-text  {
      height: 200px;
      width: 100%;
      border-radius: 10px;
      border: 2px solid #000;
      resize: none;
      margin-top: 40px;
      background-color: rgba($color: #000000, $alpha: 0);
    }
    textarea {
      height: 100%;
      width: 100%;
      border: none;
      resize: none;
      border-radius: 7px;
      background-color: rgba($color: #000000, $alpha: 0);
    }
    &__policy {
      position: relative;
      display: flex; 
      background-color: rgba($color: #000000, $alpha: 0);
      position: relative;
    }
    .button {
      position: relative;
      margin-top: 15px;
      width: 40%;
      height: 45px;
      background-color: rgba($color: #000000, $alpha: 0);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      transition: 0.5s all;
      border-radius: 8px; 
      transition: all 0.3s;
    }
    .button:hover {
      transform: scale(1.04);
      transition: transform 0.3s ;
      transition: all 0.3s;
      color: #ffffff;
      border: 0px solid #000;
      border-radius: 10px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
    }
    .button:focus {
      transform: scale(1.04);
      transition: transform 0.3s ;
      transition: all 0.3s;
      color: #ffffff;
      border: 0px solid #000 !important;
      border-radius: 10px;
      box-shadow: 
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 25px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink);
      outline: none;
      border: none;
    }
    .button:active {
      transform: scale(1);
    }
    .form .button:hover {
      color: #fff;
      transition: 0.5s all; 
    }
    .form__box-textarea::placeholder {
      color: #fff;
    }
  }
  .social-icon {
    transition: all 0.3s ease-in-out;
  }
  .social-icon:hover {
    transform: scale(1.3);
    transition: transform 0.3s ease-in-out;
    filter: 
      drop-shadow(0 0 3px var(--primary-color-pink)) 
      drop-shadow(0 0 5px var(--primary-color-pink)) 
      drop-shadow(0 0 7px var(--primary-color-pink)) ;
    transition: opacity 1s ease-in-out, transform 0.3s; 
    will-change: transform, opacity;
  }
  .social-icon:active {
    transform: scale(1);
  }
  .block {
    border: none !important;
  }
  .connect .form-text {
    border: none !important;
  }
  

  &__modal-close-button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    display: block;
    height: 40px;
    width: 40px;
    position: absolute !important;
    top: 20px;
    right: 20px;
    z-index: 10000;
    &:hover &__line {
      background-color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
        scale: 1.1;
       
        box-shadow: 
          0 0 5px #fff,
          0 0 5px #fff,
          0 0 10px var(--primary-color-pink),
          0 0 20px var(--primary-color-pink),
          0 0 20px var(--primary-color-pink),
          0 0 45px var(--primary-color-pink);
    }
    &__line {
      display: block;
      margin: 8px 0;
      background-color: #000;
      height: 4px;
      width: 40px;
      transition: all 0.5s;
      border: 2px solid #fff;
      border-radius: 10px;
  
  
      box-shadow: 
        0 0 2px #fff,
        0 0 2px #fff;
  
      &_white {
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
        box-shadow: 
          0 0 1px #fff,
          0 0 1px #fff,
          0 0 10px var(--primary-color-purple),
          0 0 10px var(--primary-color-purple),
          0 0 25px var(--primary-color-purple),
          0 0 10px var(--primary-color-purple);
      }
    }
    &__rotate-45 {
      transform: rotate(45deg);
      position: relative;
      top: 7px;
      transition: all 0.5s;
    }
    &__rotate-minus-45 {
      transform: rotate(-45deg);
      position: relative;
      top: -5px;
      transition: all 0.5s;
    }
  }

  .block:before, .block:after {
    box-shadow: none;
  }

}

.bgtransp {
  background-color: rgba($color: #000000, $alpha: 0.8);
}


.connect-visible {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}
.connect-not-visible {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}