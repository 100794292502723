.projects {
  z-index: 100;
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 95px 0 0 0;
  p {
    line-height: 24px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  &__tab-component {
    height: 100%;
  }
  &__card {
    position: relative;
    width: 23%;
    height: 38vh;
    background-color: #252526;
    border-radius: 20px;
    padding: 20px;
    margin: 10px 10px;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;
    h3 {
      font-size: 22px;
      margin-top: 8px;
      margin-bottom: 6px;
      color: #fff;
    }
    .projects__hashtags {
      position: absolute;
      bottom: 15px;
      display: flex;
      column-gap: 7px;
      flex-wrap: wrap;
      .hashtag_0 {
        width: unset !important;
        color: var(--primary-color-pink);
        text-shadow:
          0 0 1px var(--primary-color-pink),
          0 0 2px var(--primary-color-pink),
          0 0 3px var(--primary-color-pink);
        font-size: 20px;
      }
      .hashtag_1 {
        width: unset !important;
        color: var(--primary-color-blue);
        text-shadow:
          0 0 1px var(--primary-color-blue),
          0 0 2px var(--primary-color-blue),
          0 0 3px var(--primary-color-blue);
        font-size: 20px;
      }
      .hashtag_2 {
        width: unset !important;
        color: #00FF00;
        text-shadow:
          0 0 1px #00FF00,
          0 0 2px #00FF00,
          0 0 3px #00FF00;
        font-size: 20px;
      }
    }
  }
  &__card_pink:hover {
    box-shadow: 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 25px var(--primary-color-pink); 
  }
  &__card_blue:hover {
    box-shadow: 
      0 0 10px var(--primary-color-blue),
      0 0 10px var(--primary-color-blue),
      0 0 10px var(--primary-color-blue), 
      0 0 10px var(--primary-color-blue), 
      0 0 25px var(--primary-color-blue);
  }
  &__card-img-holder {
    position: relative;
    width: 100%;
    height: 60%;
    border-radius: 10px;
    overflow: hidden;
  }
  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__wrapper-for-links {
    position: relative;
    display: flex;
    height: unset !important;
    width: 50% !important;
    justify-content: flex-end;
    gap: 15px;
    right: 3%;
    bottom: 14px;
  }
  &__card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    transition: scale 0.5s ease-in-out;
    &:hover {
      scale: 1.1;
    }
  }
  &__button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    &_block {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }
  &__link-wrapper {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #383838, $alpha: 1);
    border-radius: 100%;
    transition: all 0.3s;
    border-radius: 10px;
    img {
      height: 70%;
      width: 70%;
    }
    &:hover {
      background-color: #fff;
      transition: all 0.3s;
      transform: scale(1.2);
    }
    &:hover img {
      filter: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(90%);
    }
  }
  &__link-wrapper1 {
    border: 2px solid var(--primary-color-pink);
    box-shadow: 
      0 0 5px var(--primary-color-pink),
      0 0 10px var(--primary-color-pink);
  }
  &__link-wrapper2 { 
    border: 2px solid var(--primary-color-blue);
    box-shadow: 
      0 0 5px var(--primary-color-blue),
      0 0 10px var(--primary-color-blue);
  }
  &__tab-button {
    position: relative;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 0.3s;
    border: none;
    font-size: 22px;
    width: 200px;
    background: linear-gradient(148deg, rgba(5,0,253,1) 0%, rgba(214,0,163,1) 100%);
    &1:hover {
      scale: 1.1;
      border-radius: 10px;
      box-shadow: 
        0 0 7px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 21px var(--primary-color-pink),
        0 0 27px var(--primary-color-pink);   
    }
    &1:active {
      scale: 1;
    }
    &2:hover {
      scale: 1.1;
      border-radius: 10px;
      box-shadow: 
        0 0 7px var(--primary-color-blue),
        0 0 10px var(--primary-color-blue),
        0 0 10px var(--primary-color-blue),
        0 0 21px var(--primary-color-blue),
        0 0 27px var(--primary-color-blue);  
    }
    &2:active {
      scale: 1;
    }
    &_active1 {
      box-shadow: 
        0 0 7px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 10px var(--primary-color-pink),
        0 0 21px var(--primary-color-pink),
        0 0 22px var(--primary-color-pink);   
    }
    &_active2 {
      box-shadow: 
        0 0 7px var(--primary-color-blue),
        0 0 10px var(--primary-color-blue),
        0 0 10px var(--primary-color-blue),
        0 0 21px var(--primary-color-blue),
        0 0 22px var(--primary-color-blue);  
    }
  }

  .slick-slider {
    height: 90%;
    width: 90%;
    margin: 0 auto;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    padding: 10px;
    div {
      height: 100%;
      width: 100%;
    }
  }
  .slick-prev {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 50px;
    width: 40px;
    left: -4.2% ;
    z-index: 30000000 !important;
  }
  .slick-prev:before {
    content: '<' !important;
    font-size: 50px !important;
    text-shadow: 
      0 0 3px #fff, 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 21px var(--primary-color-pink), 
      0 0 42px var(--primary-color-pink) !important;
    font-family: "Roboto", sans-serif !important;
    opacity: 1 !important;
    transition: all 0.2s ease !important;
  }
  .slick-prev:hover:before {
    scale: 1.2 !important;
    text-shadow: 
      0 0 3px #fff, 
      0 0 7px var(--primary-color-pink), 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 42px var(--primary-color-pink) !important;
  }
  .slick-prev:active:before {
    scale: 1 !important;
  }
  .slick-next {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 50px;
    width: 40px;
    right: -4.2%;
    z-index: 30000000 !important;
  }
  .slick-next:before {
    content: '>';
    font-size: 50px !important;
    text-shadow: 
      0 0 3px #fff, 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 21px var(--primary-color-pink), 
      0 0 42px var(--primary-color-pink) !important;
    font-family: "Roboto", sans-serif !important;
    opacity: 1 !important;
    transition: all 0.2s ease !important;
  }
  .slick-next:hover:before {
    scale: 1.2 !important; 
    text-shadow: 
      0 0 3px #fff, 
      0 0 7px var(--primary-color-pink), 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 42px var(--primary-color-pink) !important;
  }
  .slick-next:active:before {
    scale: 1 !important;
  }
  .slick-dots {
    bottom: -35px !important;
    li {
      margin: 0px 15px;
    }
    li button:before {
      display: none;
    }
    li button {
      border-radius: 100% !important;
      border: 2px solid #fff !important;
      box-shadow: 
        0 0 1px #fff, 
        0 0 1px #fff, 
        0 0 5px var(--primary-color-pink), 
        0 0 10px var(--primary-color-pink), 
        0 0 12px var(--primary-color-pink), 
        inset 0 0 10px var(--primary-color-pink) !important;
      opacity: 1 !important;
      transition: all 0.25s;
    }
    li:hover button {
      scale: 1.2 !important;
      box-shadow: 
        0 0 1px #fff, 
        0 0 1px #fff, 
        0 0 10px var(--primary-color-pink), 
        0 0 10px var(--primary-color-pink), 
        0 0 10px var(--primary-color-pink), 
        0 0 10px var(--primary-color-pink), 
        inset 0 0 10px var(--primary-color-pink) !important;
    }
    li:hover button:active {
      scale: 1 !important;
    }
    .slick-active button{
      background-color: #fff;
    }
  }

  .text_pink {
    text-shadow: 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 21px var(--primary-color-pink), 
      0 0 42px var(--primary-color-pink);
  }
  .text_blue {
    text-shadow: 
      0 0 7px var(--primary-color-blue), 
      0 0 10px var(--primary-color-blue), 
      0 0 21px var(--primary-color-blue),
  }

  .projects__card__header1 {
    line-height: 30px;
    text-shadow:
    0 0 7px var(--primary-color-pink),
    0 0 10px var(--primary-color-pink),
    0 0 10px var(--primary-color-pink),
    0 0 10px var(--primary-color-pink),
    0 0 21px var(--primary-color-pink);
  }
  .projects__card__header2 {
    line-height: 30px;
    text-shadow:
    0 0 7px var(--primary-color-blue),
    0 0 10px var(--primary-color-blue),
    0 0 10px var(--primary-color-blue),
    0 0 10px var(--primary-color-blue),
    0 0 21px var(--primary-color-blue);
  }
}

.projects-visible {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.projects-not-visible {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}