@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400'); 
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root {
  --primary-color-pink: #FF00FF;
  --primary-color-purple: #bc13fe;
  --primary-color-blue: #00FFFF;
  --text-color: #ffffff;
}
html, body {
  height: 100%;
}
#root {
  height: 100%;
}
* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  overscroll-behavior: none;
  // outline: 2px solid red;
}

.fz-text {
  font-size: 20px;
}
.fz-header {
  font-size: 24px;
}
button:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
}
.hidden {
  display: none !important;
}
.show {
  display: flex !important;
}
.scroll-fix {
  position: absolute;
  top: 0;
}




.section__header {
  line-height: 30px;
  text-shadow:
    0 0 4px #fff,
    0 0 7px var(--primary-color-pink),
    0 0 10px  var(--primary-color-pink),
    0 0 10px  var(--primary-color-pink),
    0 0 21px  var(--primary-color-pink),
    0 0 42px  var(--primary-color-pink); 
  padding: 12px 15px 8px 15px !important;
  display: inline-block;
  border-radius: 15px;
  background-color: rgba($color: black, $alpha: 0.7);
}
.section__header_text{
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 5px;
}

.block {
	background: rgba($color: #000000, $alpha: 0);
  z-index: 100;
  background: linear-gradient(130deg, rgb(5, 0, 253) 10%, rgb(214, 0, 163) 85%);
  opacity: 0.9;
  &_contacts {
    border-radius: 7px;
  }
  &_menu {
    border: none !important;
  }
  &_sub-title {
    text-shadow: 
      0 0 2px #fff, 
      0 0 7px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink), 
      0 0 10px var(--primary-color-pink);
      display: block;
      margin: 15px 0 0px 7px;
      overflow: visible;
  }
  &_sub-text {
    display: block;
    padding-left: 20px;
  }
 
}


.bubble_wrap {
  padding: 10px 15px;
  border-radius: 15px;
  background-color: rgba($color: black, $alpha: 0.7);
  line-height: 27px;
  height: 90%;
  p {
    height: 100%;
    overflow: auto;
    overscroll-behavior: none;
    position: relative;
  }
}


::-webkit-scrollbar {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #545454;
  border-radius: 5px; 
  display: none;
}
::-webkit-scrollbar-track {
  background-color: #2C2C2C;
  display: none;
}
::-webkit-scrollbar-button {
  display: none;
  background-color: #ccc; 
}
::-webkit-scrollbar-corner {
  background-color: #f1f1f1; 
  display: none;
}

.main {
  height: 100%;
  width: 100%;
  background-color: #4E54C8;
  &-section0 {
    background-image: url('./assets/bg/bg.webp');
    background-position: top center;
    background-size: cover ;
    background-repeat: no-repeat;
  }
  &-section1 {
    background-image: linear-gradient(322deg, rgba(78,84,200,1) 20%, rgba(96,145,186,1) 67%, rgba(96,145,186,1) 100%);
    background-repeat: no-repeat;
  }
}



// .interface-container {
//   display: flex;
//   flex-direction: column;
//   height: 500%;
//   // overflow: hidden;
//   position: relative;
//   top: 0;
//   left: 0;
// }


.area {
  display: none !important;
}


